import request from '@/utils/request'
import {objectToQueryString} from '@/utils/utils';

//查询巡检人员列表
export function inspectorList(params) {
    return request.post('/inspector/inspectorList?' + objectToQueryString(params))
}

export function inspectorDetail(id){
    return request.post('/inspector/inspectorDetail?id='+id)
}

//离职
export function departInspector(id) {
    return request.post('/inspector/departInspector?id=' + id)
}

//添加巡检员
export function addInspector(body) {
    return request.post('/inspector/addInspector?' + objectToQueryString(body))
}

//编辑巡检员
export function editInspector(body) {
    return request.post('/inspector/editInspector?' + objectToQueryString(body))
}

//强制签退
export function forcedSignOut(id) {
    return request.post('/examineManageController/forcedSignOut?id=' + id)
}

//修改密码
export function changePassword(body) {
    return request.post('examineManageController/changePassword', body)
}

// 根据参数查询小票模版列表
export function getTicketTemplateByPage(params) {
    return request.get('/template/getTemplateByTenantOrRegionId?' + objectToQueryString(params))
}

// 保存编辑
export function saveEdit(params) {
    return request.post('/template/updateTemplate', params);
}

// 保存创建
export function saveCreate(params) {
    return request.post('/template/createTemplate', params);
}

export function removeTicketTemplate(id) {
    return request.post('/template/deleteTemplateById?id=' + id)
}

// 小票记录列表
export function getTicketingRecords(params) {
    return request.post('/inspector/getTicketingRecords?' + objectToQueryString(params))
}

// /inspector/getTicketingTotalRecords
// 小票记录报表
export function getTicketingTotalRecords(params) {
    return request.post('/inspector/getTicketingTotalRecords?' + objectToQueryString(params))
}
