export default {
    data() {
        return {
            viewRightsList: []
        }
    },
    created() {
        const path = this.$route.path;

        // 获取所有的权限
        const permissions = JSON.parse(window.localStorage.getItem("permissions"));
        const permissionId = permissions.filter(item => item.menuurl === path)[0].id;
        this.viewRightsList = permissions.filter(item => item.isNavigation === 0).filter(item => item.parentId === permissionId);
    },
    mounted() {

    },
    methods: {
        hasRights(code) {
            return this.viewRightsList.findIndex(item => item.permissionCode === code) >= 0;
        }
    }
}
