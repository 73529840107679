import request from '@/utils/request'
import {objectToQueryString} from '@/utils/utils';

//查询巡检人员列表
export function getInspectors(params) {
    return request.get('/examineManageController/getInspectors?' + objectToQueryString(params))
}

//添加巡检员或编辑
export function saveInspectorForInstitutionsAndInstitutions(body) {
    return request.post('/examineManageController/saveInspectorForInstitutionsAndInstitutions', body)
}
