<template>
  <div class="page">
    <div class="search-box section">
      <el-form
          ref="query"
          :inline="true"
          :model="query"
          class="demo-form-inline"
          size="medium"
      >
        <!-- <el-form-item prop="settlementId">
          <Settlement v-model="query.settlementId" style="width: 260px" />
        </el-form-item> -->
        <el-form-item prop="phone">
          <el-input
              v-model="query.phone"
              clearable
              placeholder="请输入手机号手机号"
              prefix-icon=""
              style="width: 260px"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item prop="workStatus">
          <el-select
            v-model="query.workStatus"
            placeholder="请选择-工作状态"
            style="width: 260px"
            clearable
          >
            <el-option label="在职" value="0"></el-option>
            <el-option label="离职" value="1"></el-option>
          </el-select>
        </el-form-item> -->
        <!-- <el-form-item prop="authenticationStatus">
          <el-select
            v-model="query.authenticationStatus"
            placeholder="请选择-认证状态"
            style="width: 260px"
            clearable
          >
            <el-option label="未通过" value="1"></el-option>
            <el-option label="未认证" value="2"></el-option>
            <el-option label="待签约" value="3"></el-option>
            <el-option label="已认证" value="4"></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item>
          <el-button type="primary" @click="doSearch">搜索</el-button>
          <el-button @click="resetQueryForm">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="content-box section">
      <div class="op-box">
        <div class="op-content">
          共计<span class="total-size">{{ pagination.totalSize }}</span
        >条信息
        </div>
        <div class="op-extra">
          <icon-button
              v-if="hasRights('002001002001')"
              icon="add-select"
              title="添加巡检员"
              @click.native="goAdd"
          />
          <icon-button
              v-if="hasRights('002001002002')"
              icon="share"
              title="导出"
              @click.native="exportData"
          />
        </div>
      </div>
      <el-table
          v-loading="isLoading"
          :data="dataList"
          border
          class="common-table"
          size="medium"
          style="width: 100%"
      >
        <el-table-column
            align="center"
            label="巡检编号"
            prop="userCode"
            width="300"
        >
        </el-table-column>
        <el-table-column
            align="center"
            label="姓名"
            prop="userName"
            width="300"
        >
        </el-table-column>
        <el-table-column align="center" label="手机号" prop="phone" width="300">
        </el-table-column>
        <el-table-column
            align="center"
            label="绑定停车场"
            prop="parkingLotNames"
            width="300"
        >
        </el-table-column>
        <el-table-column align="center" label="性别" width="200">
          <template slot-scope="scope">
            {{
              scope.row.sex === "0"
                  ? "未设置"
                  : scope.row.sex === "1"
                      ? "男"
                      : "女"
            }}
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            label="黑白名单权限"
            prop="isBWcheck"
            width="200"
        >
          <template slot-scope="scope">
            {{ scope.row.isBWcheck === "0" ? "可查看" : "不可查看" }}
          </template>
        </el-table-column>

        <el-table-column align="center" fixed="right" label="操作" width="200">
          <template slot-scope="scope">
            <el-button
                v-if="hasRights('002001002003')"
                class="common-text"
                size="small"
                type="text"
                @click="goEdit(scope.row)"
            >编辑
            </el-button
            >
            <!-- <el-button
              type="text"
              size="small"
              class="common-text"
              @click="goQuit(scope.row)"
              >离职</el-button
            > -->
            <el-button
                v-if="hasRights('002001002004')"
                class="common-text"
                size="small"
                type="text"
                @click="goChangePassword(scope.row)"
            >修改密码
            </el-button
            >
            <!-- <el-button
              type="text"
              size="small"
              class="common-text"
              @click="goForceQuit(scope.row)"
              >强制签退</el-button
            > -->
            <!-- <el-button
              type="text"
              size="small"
              class="common-text"
              @click="goQuit(scope.row.id)"
              >生成二维码</el-button
            > -->
            <!-- <el-button
              type="text"
              size="small"
              class="common-text"
              @click="goQuit(scope.row.id)"
              >认证</el-button
            > -->
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-box">
        <el-pagination
            :current-page="pagination.pageNo"
            :page-size="pagination.pageSize"
            :total="pagination.totalSize"
            background
            layout="sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>

    <!-- 添加巡检员弹窗 -->
    <el-dialog
        :before-close="onInspectorDialogBeforeClosed"
        :title="title"
        :visible.sync="inspectorDialogVisible"
        append-to-body
        class="common-dialog"
        width="700px"
    >
      <div v-loading="isInspectorLoading">
        <el-form
            ref="model"
            :inline="true"
            :model="model"
            :rules="rules"
            size="medium"
            style="margin-left: 50px"
        >
          <el-form-item
              label="选择停车场"
              label-width="120px"
              prop="parkingLotId"
              required
          >
            <el-select
                v-model="model.parkingLotId"
                placeholder="请选择停车场"
                style="width: 400px"
            >
              <el-option
                  v-for="item in options"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
              ></el-option>
            </el-select>

            <!-- <SuggestParking
            v-model="model.parkingLotIds"
            style="width: 400px"
            :multiple="true"
          /> -->
          </el-form-item>

          <el-form-item label="姓名" label-width="120px" prop="name" required>
            <el-input
                v-model="model.name"
                clearable
                placeholder="姓名"
                prefix-icon=""
                style="width: 400px"
            ></el-input>
          </el-form-item>

          <el-form-item
              label="手机号码"
              label-width="120px"
              prop="phoneNum"
              required
          >
            <el-input
                v-model="model.phoneNum"
                clearable
                placeholder="手机号码"
                prefix-icon=""
                style="width: 400px"
            ></el-input>
          </el-form-item>

          <el-row>
            <el-form-item label="性别" label-width="120px" prop="sex" required>
              <el-radio-group v-model="model.sex">
                <el-radio label="1">男</el-radio>
                <el-radio label="2">女</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-row>

          <el-row>
            <el-form-item
                label="黑白名单权限"
                label-width="120px"
                prop="isBWcheck"
                required
            >
              <el-radio-group v-model="model.isBWcheck">
                <el-radio label="0">可查看</el-radio>
                <el-radio label="1">不可查看</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-row>

          <el-form-item label="邮箱" label-width="120px" prop="mailbox">
            <el-input
                v-model="model.mailbox"
                clearable
                placeholder="邮箱地址"
                prefix-icon=""
                style="width: 400px"
            ></el-input>
          </el-form-item>

          <el-form-item label="选择头像" label-width="120px" prop="avatar">
            <el-upload
                ref="headImageUpload"
                :http-request="doHeadImageUpload"
                :show-file-list="false"
                action=""
                class="avatar-uploader"
                list-type="picture"
            >
              <Picture
                  v-if="model.avatar"
                  :attachId="model.avatar"
                  class="avatar"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer" style="text-align: right">
          <el-button @click="cancelAddOrEdit">取 消</el-button>
          <el-button type="primary" @click="saveAddOrEdit">确定</el-button>
        </div>
      </div>
    </el-dialog>

    <!-- 修改密码弹窗 -->
    <el-dialog
        :visible.sync="changePasswordDialogVisible"
        append-to-body
        class="common-dialog"
        title="修改密码"
        width="400px"
    >
      <el-form
          ref="changeData"
          v-loading="isChangeLoading"
          :inline="true"
          :model="changeData"
          :rules="rulesChangeData"
          size="medium"
      >
        <el-form-item
            label="修改密码"
            label-width="100px"
            prop="newPassword"
            required
        >
          <el-input
              v-model="changeData.newPassword"
              clearable
              placeholder="请输入新密码"
              prefix-icon=""
              style="width: 260px"
              type="password"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancelChangePassword">取 消</el-button>
        <el-button type="primary" @click="saveNewPassword">确定</el-button>
      </div>
    </el-dialog>
    <!-- 导出弹窗 -->
    <el-dialog
        :append-to-body="true"
        :visible.sync="exportDialogFormVisible"
        class="common-dialog"
        title="导出选项"
        width="600px"
    >
      <el-form ref="form" :model="form" class="label-weight">
        <el-form-item label="导出条数" label-width="120px" prop="exportSize">
          <el-col :span="16">
            <el-input
                v-model="form.exportSize"
                placeholder="请填写导出条数"
            ></el-input>
          </el-col>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirmExport">确认导出</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import BaseMixin from "@/mixins/base";
import Permission from "@/mixins/permission";
// import { findParkingBySettlementId } from "@/api/settlementManage";
import {doUploadImage, getParkingList} from "@/api/parking";
import {getInspectors, saveInspectorForInstitutionsAndInstitutions,} from "@/api/share";
import {changePassword, departInspector} from "@/api/patrolInspection";
import Picture from "@/components/Picture";
import {doExport} from "@/api/common";

export default {
  name: "share_person_manage",
  mixins: [BaseMixin, Permission],
  components: {
    Picture,
  },
  data() {
    return {
      query: {
        phone: "",
        isExport: "0", //是否导出
        exportSize: 1, //导出条数
      },
      exportDialogFormVisible: false, //导出弹窗
      form: {
        exportSize: 1,
      },
      dataList: [],
      inspectorDialogVisible: false, //添加编辑巡检员弹窗
      isInspectorLoading: false, //提交添加编辑loading
      title: "",
      options: [], //车场列表选项
      model: {
        id: "",
        parkingLotId: "",
        parkingLotIds: [],
        name: "",
        phoneNum: "",
        sex: "1",
        isBWcheck: "0",
        mailbox: "",
        avatar: "",
      },
      rules: {
        parkingLotIds: [{required: true, message: "请选择停车场"}],
        name: [{required: true, message: "姓名不能为空"}],
        phoneNum: [{required: true, message: "手机号不能为空"}],
        sex: [{required: true, message: "请选择性别"}],
        isBWcheck: [{required: true, message: "请选择是否可查看黑白名单"}],
      },
      changePasswordDialogVisible: false, //修改密码弹窗
      isChangeLoading: false, //提交修改密码loading
      changePasswordId: "",
      changeData: {
        newPassword: "",
      },
      rulesChangeData: {
        newPassword: [{required: true, message: "新密码不能为空"}],
      },
    };
  },
  //监听选中的结算方，查询当前结算方关联车场
  // watch: {
  //   "model.settlementId"(newVal) {
  //     const pageSize = 9999;
  //     const pageNo = 1;
  //     const id = newVal;
  //     findParkingBySettlementId(id, pageSize, pageNo).then((res) => {
  //       if (res && res.code === 30 && res.result) {
  //         this.options = res.returnObject.list;
  //       }
  //     });
  //   },
  // },
  methods: {
    //重置查询条件
    resetQueryForm() {
      this.$refs.query.resetFields();
      this.doSearch();
    },
    //查询
    doSearch() {
      this.pagination.pageNo = 1;
      this.pagination.pageSize = 10;
      this.search();
    },
    async search() {
      this.isLoading = true;
      const params = this.paramFormat(this.query);
      const res = await getInspectors(params);
      this.isLoading = false;
      if (res && res.code === 30 && res.result) {
        const returnObject = res.returnObject;
        this.dataList = returnObject.list;
        this.pagination.totalSize = returnObject.count;
        this.pagination.totalPages = returnObject.totalPage;
        this.form.exportSize = returnObject.count;
      }
    },
    //导出弹窗
    exportData() {
      this.exportDialogFormVisible = true;
    },
    //确认导出
    confirmExport() {
      const loading = this.$loading({
        lock: true,
        text: "正在导出",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0)",
      });
      const params = this.paramFormat(this.query);
      params["isExport"] = "1";
      params["exportSize"] = this.form.exportSize;
      // window.open("http://172.16.69.129:81" + "/examineManageController/getPersonnelManage?" + objectToQueryString(params));
      doExport(
          "/examineManageController/getInspectors",
          params,
          "巡检人员.xlsx"
      );
      loading.close();
      this.exportDialogFormVisible = false;
    },
    //添加巡检员
    goAdd() {
      this.title = "添加巡检员";
      this.model.id = "";
      // this.model.settlementId = "";
      this.model.parkingLotIds = "";
      this.model.phoneNum = "";
      this.model.sex = "1";
      this.model.name = "";
      this.model.avatar = "";
      this.model.mailbox = "";
      this.model.isBWcheck = "0";
      this.inspectorDialogVisible = true;
    },
    // 添加/维护巡检员信息弹窗关闭时清理表单
    onInspectorDialogBeforeClosed(done) {
      this.$refs.model.resetFields();
      done();
    },
    //编辑巡检员
    goEdit(e) {
      this.title = "编辑巡检员";
      this.model.id = e.id;
      // this.model.settlementId = e.settlementId;
      this.model.parkingLotId = e.parkingLotIds[0];
      this.model.phoneNum = e.phone;
      this.model.sex = e.sex;
      this.model.isBWcheck = e.isBWcheck;
      this.model.name = e.userName;
      this.model.avatar = e.avatar;
      this.model.mailbox = e.email;
      this.inspectorDialogVisible = true;
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader(); //定义方法读取文件
        reader.readAsDataURL(file); //开始读文件  本身是图片的二进制数据 进行base64加密形成字符串
        reader.onload = () => resolve(reader.result); //成功返回对应的值 reader.result可以直接放在img标签中使用
        reader.onerror = (error) => reject(error); //失败返回失败信息
      });
    },
    // 上传头像图片
    async doHeadImageUpload(file) {
      const isLimit = file.file.size / 1024 < 1000;
      if (!isLimit) {
        this.$message.warning("图片大小不能超过1M! 请重新上传");
        return;
      }
      const base64 = await this.getBase64(file.file);
      const res = await doUploadImage(base64);
      if (res && res.code === 30 && res.result) {
        this.model.avatar = res.returnObject.id;
      }
    },

    //取消添加或编辑巡检员
    cancelAddOrEdit() {
      this.inspectorDialogVisible = false;
      this.$refs.model.resetFields();
    },
    //确定添加或编辑巡检员
    saveAddOrEdit() {
      this.$refs.model.validate((valid) => {
        if (valid) {
          this.isInspectorLoading = true;
          const parkingId = [];
          if (parkingId.length <= 0) {
            parkingId.push(this.model.parkingLotId);
          }
          this.model.parkingLotIds = parkingId;
          console.log(this.model.parkingLotIds);
          saveInspectorForInstitutionsAndInstitutions(this.model).then(
              (res) => {
                setTimeout(() => {
                  this.isInspectorLoading = false;
                  if (res && res.code === 30 && res.result) {
                    this.$message.success("保存成功！");
                    this.inspectorDialogVisible = false;
                    this.$refs.model.resetFields();
                    this.doSearch();
                  }
                }, 2000);
              }
          );
        } else {
          return false;
        }
      });
    },

    //修改密码
    goChangePassword(e) {
      this.changePasswordId = e.id;
      this.changePasswordDialogVisible = true;
    },
    //取消修改密码
    cancelChangePassword() {
      this.changePasswordDialogVisible = false;
      this.$refs.changeData.resetFields();
    },
    //确定修改密码
    saveNewPassword() {
      this.$refs.changeData.validate((valid) => {
        if (valid) {
          const id = this.changePasswordId;
          const password = this.changeData.newPassword;
          const body = {
            id: id,
            password: password,
          };
          this.isChangeLoading = true;
          changePassword(body).then((res) => {
            this.isChangeLoading = false;
            if (res && res.code === 30 && res.result) {
              this.$message.success("修改密码成功！");
              this.changePasswordDialogVisible = false;
              this.$refs.changeData.resetFields();
              this.doSearch();
            }
          });
        } else {
          return false;
        }
      });
    },
    // 离职操作
    goQuit(e) {
      this.$confirm("确定要给该人员办理离职吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        const id = e.id;
        this.isLoading = true;
        departInspector(id).then((res) => {
          setTimeout(() => {
            this.isLoading = false;
            if (res && res.code === 30 && res.result) {
              this.$message.success("办理离职成功！");
              this.doSearch();
            }
          }, 2000);
        });
      });
    },
    //获取停车场列表
    getParkingList() {
      const params = {
        parkingType: "",
        parkingStatus: "",
        settlementId: "",
        regionId: "530129",
        parkingName: "",
        motionType: 1,
        pageNo: 1,
        pageSize: 999,
      };
      getParkingList(params).then((res) => {
        if (res && res.code === 30 && res.result) {
          this.options = res.returnObject.list;
        }
      });
    },
  },
  mounted() {
    this.getParkingList();
  },
};
</script>

<style lang="less" scoped>
.avatar-uploader {
  /deep/ .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
}

.avatar-uploader .el-upload:hover {
  border-color: #0768FD;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
